.inverted.big.bars.icon, .arrow.left.icon.mobile {
    position: absolute;
    left: 10px;
}

.inverted.big.home.icon {
    position: absolute;
    left: 50px;
}

.inverted.big.search.icon {
    position: absolute;
    right: 10px;
}

.arrow.left.icon {
    height: auto;
    color: #215B8B !important;
}

.ui.inverted.header, .back-header-container .heading {
    width: 100%;
    margin: 0;
}

.heading {
    color: #215B8B !important;
}

.main-container {
    height: 100vh;
}

.body-container {
    height: 93vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.service-result-container {
    height: 93vh;
    overflow-x: unset;
    margin: 0 !important;
}

.search-selection-menu {
    text-align: center;
}

.body-container::-webkit-scrollbar {
    display: none;
}

.ui.vertical.labeled.icon.ui.overlay.left.thin.visible.sidebar.menu {
    min-width: 80vw;
}

.home-menu-drawer-container {
    padding: 0 !important;
}

.ui.stretched.one.column.grid.padded {
    margin: 4rem 1rem !important;
}

.ui.stretched.one.column.grid.padded .ui.fluid.scrolling.dropdown {
    border-bottom: #dfdfdf 1px solid;
    padding-bottom: 10px;
}

.back-header-container {
    height: 5vh;
    padding: 7px;
    display: flex;
    align-items: center;
    background: #C2E3FF;
}

.mobile-webmap {
    height: 50vh;
}

.ui.mini.fluid.button {
    margin-bottom: 10px;
}

.map-result-container {
    height: 21vh;
    overflow-x: unset;
}

.georesults-segment {
    height: 48vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: unset !important;
}

.mobile-filter {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: none !important;
    line-height: normal !important;
    padding: 1.5vh !important;
}

.ui.vertical.ui.overlay.bottom.visible.sidebar.menu .item {
    margin: 2rem;
    text-align: center;
}

.online-results-segment {
    overflow: auto;
    height: 88vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#directions-container {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ui.dimmer {
    z-index: 1901;
}

.no-directions-available {
    text-align: center;
}

.georesults-segment .ui.primary.button, .online-results-segment .ui.primary.button {
    margin-top: 1rem !important;
}

.georesults-segment::-webkit-scrollbar, 
.main-container::-webkit-scrollbar,
.online-results-segment::-webkit-scrollbar,
#directions-container::-webkit-scrollbar {
    display: none;
}

.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
    display: inline-block;
    margin-right: 5px !important;
}

.ui.icon.menu .item {
    text-align: left;
}

.ui.bottom.left.popup.transition.visible, .ui.menu.transition.visible {
    padding: 0;
}

.ui.stackable.four.column.grid {
    width: 100%;
}
 
.ui.popup.mobile-filter-dropdown .menu {
    border: none;
    box-shadow: none;
    width: 150px;
    min-height: unset;
}

.ui.icon.menu .icon:before {
    font-size: large;
    opacity: 1;
}

.ui.inverted.center.aligned.header{
    text-align: center;
}

.websiteLink {
    word-break: break-all;
}

footer.social-wrapper-mobile::before::after{
    box-sizing: unset;
}

footer .social-wrapper-mobile .ui.icon.button{
    box-shadow: 0 0 0 1px #aaaaaa inset!important;
    margin-right: 0.5rem;
}
  
footer .social-wrapper-mobile .ui.icon.button:hover{
    background-color: rgba(255, 255, 255, 0.5);
}
  
.support-contact-grid-mobile.ui.grid{
    background-color: #dddddd;
    margin: 0;
    padding: .5rem 1.5rem .5rem .5rem;
}

.support-contact-grid-mobile.ui.grid > .row{
    padding: 1.5rem 3.5rem 1.5rem 2.5rem;
}
  
.support-contact-grid-mobile-grid.ui.grid > .row > .column{
    padding: 0;
}

.support-contact-grid-mobile-grid.ui.grid > .row > .column .contact-details{
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details.lifeline{
    margin-right: 8px;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details.openarms{
    margin-left: 8px;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details.lifeline img{
    max-width: 100px;
    height: initial !important;
    margin-bottom: 2rem;
    margin-top: 1.58rem;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details.openarms img{
    max-width: 70px;
    height: initial !important;
    margin-bottom: 1rem;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details .ui.primary.button{
    width: 100%;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    z-index: 1;
    padding: 1rem 0;
}

.support-contact-grid-mobile.ui.grid > .row > .column .contact-details .ui.primary.button .icon{
    font-weight: 400;
    font-size: 14px;
    margin-right: 5px !important;
}

.support-contact-grid-mobile.ui.grid > .row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column {
    width: 50% !important;
}

.support-contact-grid-mobile.ui.centered.grid > .row > .column:not(.aligned):not(.justified), .ui.grid .centered.row > .column:not(.aligned):not(.justified) {
    text-align: left;
}

.ui[class*="right floated"].button, .ui[class*="right floated"].buttons {
    float: right;
    margin-right: 0;
    margin-left: .25em;
    margin-top: 0.5rem;
}

.ui[class*="left floated"].button, .ui[class*="left floated"].buttons {
    float: left;
    margin-left: 0;
    margin-right: .25em;
    margin-top: 0.5rem;
}
 
.ui.positive.message {
    margin-top: 18rem;
}

.go-back-mobile-button-container{
    width: 100%;
    border-top: 1px solid #D4D4D5;
    padding: 10px;
    background-color: #ffffff;
}

.go-back-mobile-button{
    font-size: smaller !important;
}

.go-back-text{
    color: #002060 !important ;
}

.mobile-button{
    margin: 0.1vh;
    width: 35vw;
    height: 35vw;
    align-content: center;
    text-align: center;
}

.button-container{
    background: #ffffff;
    color: #000000;
    box-shadow: 0 5px 10px rgba(0,0,0,0.20) !important;
}

@media screen and (max-width: 639px) {
    .ui.modal.transition.visible.active.search-modal {
        width: 90vw;
    }
    .search-wrapper{
        padding: 1.5rem 0.75rem 1.5rem 0;
      }

    .mobile-webmap {
        height: 50vh;
    }
}
