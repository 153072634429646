.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.legal-container {
  padding: 20px;
}

.ui.color.button{
  background-color: #002060;
  color: #fff;
  border-radius: 50%;
  margin-top: 0px;
}

.ui.anchor.footer{
  font-size: 18px;
  background-color: #292828;
  color: #e9eaef;
  float: left;
  font-family: 'Heebo', sans-serif;
}

.ui.label a{
  opacity: 1;
  color:#fff;
}
 
.map-options .ui.basic.filter-button {
  text-align: center;
  color: #eeeeee !important;
  background: transparent !important;
  height: 65px;
  outline: 0;
  font-size: 16px;
}
.map-options .ui.basic.filter-button:hover,
.map-options .ui.basic.filter-button:focus {
  color: #ffffff !important;
  background: transparent !important;
  outline: 0;
}
 
.map-options .ui.basic.filter-button .icon{
  margin-right: 8px !important;
}

.map-options .map-options-aboutme > div {
  background-color: transparent;
}

.map-options-filterbar,
.map-options-aboutme{
  z-index: 999;
}

.selection-dropdown-container .dropdown, .selection-dropdown-container .input {
  margin-top: 10px;
  margin-bottom: 10px;
}
 .selection-dropdown-container .Label{
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-modal {
  width: 500px !important; 
}

.ui.toggle.checkbox {
  display: block;
  margin: 15px 0;
}

.clear-filter {
  width: 100%;
  justify-content: center;
}

#directions-panel {
  width: 400px;
}

#directions-panel .accordion .title {
  background-color: #ffffff;
}

.esri-widget.esri-widget--panel.esri-directions__scroller {
  padding: 15px 20px;
  height: 300px;
}

.direction-point {
  padding: 10px 0;
  margin: 0;
  border-bottom: 2px solid #dddddd;
}

.ui.menu.transition.visible {
  padding: 20px;
}
.map-wrapper{
  display: flex;
  align-items: center;
}
.map-categories{
  height: calc(100vh - 85px);
  width: 400px;
  box-shadow: 1px 0 5px #00205f33;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.map-categories .map-categories-inner{
  height: 100%;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #002060;
  scrollbar-width: thin;
}
.map-categories .map-categories-inner::-webkit-scrollbar-thumb{
  background: #002060;
}
.map-categories  .map-categories-inner .map-category-options{
  width: 400px;
}
.map-webmap {
  position: relative;
  height: calc(100vh - 85px);
  width: calc(100% - 400px);
  outline: 0;
}
.webmap {
  width: 100%;
  height: calc(100%);
  outline: 0;
}

.slider-container {
  position: absolute;
  bottom: 70px;
  left: 15px;
  height: 60px;
  width: 300px;
  padding: 10px;
  background-color: #ffffff;
}

.slider-container div {
  height: 20px;
}

#buffer-slider .esri-slider__label {
  min-width: 58px;
  left: -24px;
}

#buffer-slider .esri-slider__label::after {
  left: calc(50% - 20px);
  bottom: -2px;
}

.map-categories  .map-categories-inner .map-category-options .floated.button{
  background: #C2E3FF;
  color: #215B8B;
  text-align: left;
  padding: 1.2rem 1.5rem;
  border-radius: 0;
  font-weight: 400;
}
.map-categories  .map-categories-inner .map-category-options .service-content .floated.button{
  margin-bottom: 0;
}

.map-categories  .map-categories-inner .map-category-options .accordion{
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #dddddd;
}

.map-categories  .map-categories-inner .map-category-options .accordion .title{
  padding: 1.2rem;
  border-color: #dddddd;
  color: #215B8B;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
}
.map-categories  .map-categories-inner .map-category-options .accordion .title:hover{
  color: #002060;
}

.map-categories  .map-categories-inner .map-category-options .accordion .content{
  padding: 1rem 0.5rem;
}

.map-categories  .map-categories-inner .map-category-options .accordion > .content{
  padding: 0 1rem;
}

.map-categories  .map-categories-inner .map-category-options .accordion .content .selection{
  border-top: 1px solid #dddddd;
}
.link{
  color: #2D6DA3;
  text-decoration: underline;
  font-size: 14px;
}
.services-grid .column {
  text-align: center;
}
.service-content .ui.card .content{
  border: none;
  padding: 1.75rem 1.5rem 0;
}
.service-content .ui.card > .content:last-child{
  padding-bottom: 1.5rem;
}
.service-content .ui.card .content .header i.icon{
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  color: #fa0001;
  padding: 8px;
  font-size: 20px;
  margin-right: 10px;
}
.service-content .ui.card .content .header{
  display: flex;
  color: #002060;
  font-size: 18px !important;
  letter-spacing: 0.5px;
}
.service-content .ui.card .content .header > div{
  max-width: calc(100% - 50px);
}
.service-content .ui.card .content .header > div .description{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
}
.service-content .ui.card .content .header ~ .description{
  font-size: 16px;
  color: #666666;
  font-weight: 400;
}
.service-content .ui.card .content .websiteLink{
  display: flex;
  justify-content: flex-start;
}
.service-content .ui.card .content .websiteLink button{
  font-weight: 400;
  font-size: 14px;
  padding: 0.75rem 1rem;
  white-space: nowrap;
  margin-left: 3px;
}
 
.carousel-container {
  margin-top: 50px; 
  margin-bottom: 80px;
}

.carousel-container h3{
  font-family: 'Heebo';
  margin: 20px;
}

.carousel-container .react-multi-carousel-track p{
  text-align: center;
}

.services-grid .row{
  padding: 1rem 3rem 1rem 2.3rem !important;
}
.services-grid .column{
  padding: 0 0 5px !important;
}

.services-grid .column:nth-child(1){z-index: 20;}
.services-grid .column:nth-child(2){z-index: 19;}
.services-grid .column:nth-child(3){z-index: 18;}
.services-grid .column:nth-child(4){z-index: 17;}
.services-grid .column:nth-child(5){z-index: 16;}
.services-grid .column:nth-child(6){z-index: 15;}
.services-grid .column:nth-child(7){z-index: 14;}
.services-grid .column:nth-child(8){z-index: 13;}
.services-grid .column:nth-child(9){z-index: 12;}
.services-grid .column:nth-child(10){z-index: 11;}

.services-grid img {
  width: 50px;
}

.services-grid .ui.service-grid-btn{
  padding: 0 0.5rem 0.5rem;
  text-align: center;
  min-height: 105px;
  width: 100%;
  border-radius: 8px;
  margin: 0;
  background: #ffffff;
  color: #333333;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.16);
  transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
}

/*.services-grid .ui.service-grid-btn:hover{*/
/*  background: #ffffff;*/
/*  color: #000000;*/
/*  box-shadow: 0 5px 10px rgba(0,0,0,0.20);*/
/*}*/

.services-grid .ui.service-grid-btn{
  height: 140px;
  width: 140px;
  margin-top: 2vh;
  background: #ffffff;
  color: #000000;
  box-shadow: 0 5px 10px rgba(0,0,0,0.20);
}

.footer-grid {
  margin: 0px !important;
} 

.ui.modal.transition.visible.active.search-modal {
  width: 40vw;
}

.search-modal .esri-search.esri-widget, .search-modal .esri-search__container {
  width: 100%;
}

.map-options{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.ui.popup.menu-dropdown{
  padding: 2rem;
}

.ui.popup.menu-dropdown .menu{
  border: none;
  box-shadow: none;
}

.ui.popup.about-me-menu{
  width: 100%;
  max-width: 330px !important;
}

.ui.popup.about-me-menu .menu .label{
  padding: 0;
  font-size: 14px;
  color: #333333;
  background-color: transparent;
}

.ui.popup.about-me-menu .menu .dropdown:not(.icon){
  margin: 5px 0 20px; 
}

.ui.default.dropdown:not(.button)>.text{
  color: #999999;
}

.esri-slider--horizontal .esri-slider__label{
  top: -48px !important;
  padding: 5px;
  background: #002060;
  color: white;
  border-radius: 20px;
}

.esri-slider--horizontal .esri-slider__label::after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #002060;
  bottom: -5px;
  left: calc(50% - 5px);
  transform: rotate(45deg);
}

.esri-slider--horizontal .esri-slider__thumb{
  border: 2px solid #002060;
}

.esri-slider--horizontal .esri-slider__track{
  background: #002060;
}

.search-wrapper{
  width: 100%;
  max-width: 315px;
  text-align: center;
  margin: auto;
}

.search-wrapper ~ .services-grid.ui.padded.grid:not(.vertically):not(.horizontally) {
  margin-top: -1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.search-wrapper .ui.search .prompt{
  border-radius: 0;
  width: 100%;
}

.search-wrapper .ui.search .input{
  width: 100%;
}

.search-wrapper .ui.search .input .search.icon{
  font-size: 18px;
  font-weight: 400;
  color: #cccccc;
  opacity: 1;
}

.search-wrapper  .ui.icon.input > input:focus ~ i.icon{
  color: #85b7d9;
}

.search-wrapper .ui.search>.results{
  width: 100%;
  max-height: 500px;
  overflow: auto;
  box-shadow: 0 2px 2px 0 rgba(0,32,96,0.12), 0 2px 10px 0 rgba(0,32,96,0.15);
  border-left: none;
  border-right: none;
  border-top: 1px solid rgba(0,32,96,.1);
  border-bottom: none;
  scrollbar-color: #002060;
  scrollbar-width: thin;
}

.search-wrapper .ui.search > .results::-webkit-scrollbar-thumb{
  background: #002060;
}

.search-wrapper .ui.search > .results .result{
  border-bottom: 1px solid rgba(0,32,96,.1);
  background-color: #ffffff;
  transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

.search-wrapper .ui.search > .results .result .title{
  font-weight: 400;
}

.search-wrapper .ui.search > .results .result:hover{
  background: linear-gradient(155deg, #002060, #2E6CA2);
}

.search-wrapper .ui.search > .results .result:hover .title{
  color: #eeeeee;
}

.search-wrapper .ui.search > .results > .message{
  background-color: #aaaaaa;
}
.search-wrapper .ui.search > .results > .message .header{
  color: #333333;
  font-weight: 400;
}
.support-contact-grid.ui.grid{
  background-color: #dddddd;
  margin: 0;
}
.support-contact-grid.ui.grid > .row{
  padding: 1.5rem 3.5rem 1.5rem 2.5rem;
}
.support-contact-grid.ui.grid > .row > .column{
  padding: 0;
}
.support-contact-grid.ui.grid > .row > .column .contact-details{
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.support-contact-grid.ui.grid > .row > .column .contact-details.lifeline{
  margin-right: 8px;
}
.support-contact-grid.ui.grid > .row > .column .contact-details.openarms{
  margin-left: 8px;
}
.support-contact-grid.ui.grid > .row > .column .contact-details.lifeline img{
  max-width: 150px;
  height: initial !important;
  margin-bottom: 2rem;
}
.support-contact-grid.ui.grid > .row > .column .contact-details.openarms img{
  max-width: 70px;
  height: initial !important;
  margin-bottom: 1rem;
}
.support-contact-grid.ui.grid > .row > .column .contact-details .ui.primary.button{
  width: 100%;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  z-index: 1;
  padding: 1rem 0;
}
.support-contact-grid.ui.grid > .row > .column .contact-details .ui.primary.button .icon{
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px !important;
}

.ui.two.buttons.result-filter {
  width: 92%;  
}

button.ui.active.toggle.button.result-filter-btn {
  margin: 5px;
  background: linear-gradient(155deg, #208845, #21BA45) !important;
}

button.ui.toggle.button.result-filter-btn {
  margin: 5px;
}

button.ui.primary.button,
a.ui.primary.button{
  position: relative;
  padding: 0.75rem 1rem;
  background: linear-gradient(155deg, #002060, #2E6CA2);
}

.ui.button.landingButton{
  margin: 5px;
  background: linear-gradient(155deg, #00807C, #00AC7C) !important;
  color: #eeeeee;
  width: 100px !important;
  height: 50px !important;
  font-size:  19px ;
}

.landing{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #001F60;
}

.landing-inner{
  max-width: 500px;
  width: calc(100% - 2rem);
  margin-top: 0;
  padding: 1.5rem;
  display: block;
  text-align: center;
}


.ui.primary.button:hover{
  background: linear-gradient(-155deg, #002060, #2E6CA2);
}

.other-services{
  position: absolute;
  width: calc(100% - 2rem);
  left: 1rem;
  padding: 1rem;
  background: #ffffff;
}

.other-services-mobile{
  position: absolute;
  width: 100%;
  background: #ffffff;
}

.other-services h3{
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.other-services-mobile h3{
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin: 8px;
}

.other-services,.other-services-mobile  .react-multi-carousel-item{
  margin: 0 5px;
}
.other-services  .react-multi-carousel-item .ui.card:hover{
  transform: none;
  box-shadow: 0 1px 3px 0 #00205f66, 0 0 0 1px #00205f66;
}
.other-services  .react-multi-carousel-item .ui.card > .content .header{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.other-services  .react-multi-carousel-item .ui.card > .content .header + .description{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.other-services  .react-multi-carousel-item .ui.card > .content .links{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.other-services .react-multiple-carousel__arrow{
  background-color: #00206040;
}
.other-services .react-multiple-carousel__arrow:hover{
  background-color: #002060;
}
.other-services .react-multiple-carousel__arrow--right{
  right: 0;
}
.other-services .react-multiple-carousel__arrow--left{
  left: 0;
}
.ui.list > .item.location-list .content .header{
  display: block;
  align-items: flex-start;
}
.ui.list > .item.location-list .content .header.online-header{
  align-items: center;
  width: 100%;
}
.ui.list > .item.location-list .content .header > div{
  color: #002060;
  font-size: 16px !important;
  letter-spacing: 0.5px;
}
.location-list .content .description{
  padding-left: 0 !important;
}
.location-list .content .header i.icon{
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #fa0001;
  color: #ffffff;
  padding: 7px 0;
  font-size: 16px;
  margin-right: 10px;
  box-shadow: 0 2px 6px #84000080;
}
/* .location-list .content .header > div{
  max-width: calc(100% - 50px);
} */
.location-list .content .header > div.location-header-inner .description{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  padding: 0.5rem 0 1.5rem !important;
}

.ui.relaxed.list:not(.horizontal) > .item.item.location-list {
   padding: 1rem;
}

.map-category-options .ui.styled.accordion .title{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.map-category-options .ui.styled.accordion .active.title{
  background: linear-gradient(155deg, #002060, #2E6CA2);
  color: #ffffff;
}

footer .social-wrapper .ui.icon.button{
  box-shadow: 0 0 0 1px #aaaaaa inset!important;
  margin-right: 0.5rem;
}

footer .social-wrapper .ui.icon.button:hover{
  background-color: rgba(255, 255, 255, 0.5);
}

footer .footer-legal div{
  width: 100%;
}

footer .footer-legal .ui.anchor.footer{
  color: #aaaaaa;
  font-size: 14px;
  float: none;
}

.footer-feedback .feedback-button.ui.inverted.button{
  box-shadow: 0 0 0 1px #aaaaaa inset!important;
  color: #aaaaaa;
}

.footer-feedback .feedback-button.ui.inverted.button:hover{
  background-color: rgba(255, 255, 255, 0.5);
  color: #292828;
}

@media screen and (max-width: 1000px) {
  .services-grid img {
    width: 40px;
  }
}

@media screen and (max-width: 767px) {
  .services-grid .column {
    padding-bottom: 0px;
  }

  .carousel-container {
    margin-top: 20px; 
    margin-bottom: 40px;
  }

  .footer-grid p {
    padding-top: 30px !important;
  } 
}

@media screen and (max-width: 600px) {
  .services-grid img {
    width: 30px;
  }

  .carousel-container, .carousel-container h3 {
    margin: 0;
  }
  
  .support-contact-grid i {
    padding-right: 10px;
  }

  .footer-grid p {
    padding-top: 10px !important;
  }

  .mobileCookieOverlay {
    display: flex;
    align-items: center;
  }

  .profile-modal {
    width: 90% !important; 
  }
}

.servulink-online-result-icon:before {
  display: inline-block;
  background-image: url('/assets/images/servulink-online.svg');
  content: '';
  background-size: 20px 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.servulink-geo-result-icon:before {
  display: inline-block;
  background-image: url('/assets/images/servulink-geo.svg');
  content: '';
  background-size: 20px 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.category-title {
  /* text-align: center; */
  background: #C2E3FF;
  color: #215B8B;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.3rem;
}
 
.ui.basic.blue.button.go-back-button{
   margin-left: 0.5rem !important;
   margin-top: 2.5rem !important;
}

.star{
  font-size: 1.5rem !important;
  color: gold !important;
  float: right !important;
}

.star outline{
  font-size: 1.5rem !important;
  color: gold !important;
  float: right !important;
}

.ratingSlider{
  width: 250px;
}

.numberSlider{
  word-spacing: 230px !important;
}

.blue.big.icon.home{
  margin-left: 0.5rem;
  color: #002060 !important;
}
.search-and-home-wrapper{
  display: flex;
  margin-top: 2rem;
}
